// Composables
import {
  createRouter as _createRouter,
  createWebHistory,
  createMemoryHistory,
} from "vue-router";
import config from "@/config";
import api from "@/services/api";

const siteRoutes = [
  {
    path: "/",
    component: () =>
      config.maintenance
        ? import("@/layouts/default/Maintenance.vue")
        : import("@/layouts/default/Root.vue"),
    children: [
      {
        path: "/",
        name: config.maintenance ? "Maintenance" : "Home",
        component: () => import("@/views/Shop/Home.vue"),
        // component: () =>
        //   config.maintenance
        //     ? import(/* webpackChunkName: "home" */ "@/views/Maintenance.vue")
        //     : import(
        //         /* webpackChunkName: "home" */ "@/views/Gamekeys/Home.vue"
        //       )
      },
      {
        path: "/verified",
        name: "Verified",
        component: () => import("@/views/Verified.vue"),
      },
      {
        path: "/login",
        name: "Login",
        component: () => import("@/views/Login.vue"),
      },
      {
        path: "/sign-up",
        name: "Sign Up",
        component: () => import("@/views/SignUp.vue"),
      },
      {
        path: "/contact-us",
        name: "Contact us",
        component: () => import("@/views/Contact.vue"),
      },
      {
        path: "/about-us",
        name: "About Us",
        component: () => import("@/views/Gamekeys/AboutUs.vue"),
      },
      {
        path: "/privacy-policy",
        name: "Privacy Policy",
        component: () => import("@/views/Gamekeys/PrivacyPolicy.vue"),
      },
    ],
  },
  {
    path: "/news",
    component: () =>
      config.maintenance
        ? import("@/layouts/default/Maintenance.vue")
        : import("@/layouts/default/Default.vue"),
    children: [
      {
        path: "/news",
        name: "News",
        component: () => import("@/views/Gamekeys/Blog.vue"),
      },
      {
        path: "/news/:slug",
        name: "News article",
        component: () => import("@/views/Gamekeys/BlogEntry.vue"),
        props: true,
      },
      {
        path: "/news/category/:categorySlug",
        name: "News category",
        component: () => import("@/views/Gamekeys/Blog.vue"),
      },
    ],
  },
  {
    path: "/shop",
    component: () =>
      config.maintenance
        ? import("@/layouts/default/Maintenance.vue")
        : import("@/layouts/default/Default.vue"),
    children: [
      {
        path: "/shop",
        name: "Shop",
        component: () => import("@/views/Shop/Home.vue"),
      },
      {
        path: "/shop/:category",
        name: "Shop category",
        component: () => import("@/views/Shop/Category.vue"),
      },
      {
        path: "/shop/checkout",
        name: "Checkout",
        component: () => import("@/views/Shop/Checkout.vue"),
      },
    ],
  },
];

const siteAdminRoutes = [
  {
    path: "/",
    name: "Index",
    component: () => import("@/layouts/default/AdminRoot.vue"),
    children: [
      {
        path: "/login",
        name: "Login",
        component: () => import("@/views/Login.vue"),
      },
    ],
  },
  {
    path: "/admin",
    component: () => import("@/layouts/default/Authenticated.vue"),
    children: [
      {
        path: "",
        name: "admin",
        component: () => import("@/views/Authenticated/Admin/Home.vue"),
      },
      {
        path: "/admin/leads",
        name: "adminLeads",
        component: () => import("@/views/Authenticated/Admin/Leads.vue"),
      },
      {
        path: "/admin/leads/:id",
        name: "adminLead",
        component: () => import("@/views/Authenticated/Admin/Leads.vue"),
      },
      {
        path: "/admin/users",
        name: "adminUsers",
        component: () => import("@/views/Authenticated/Admin/Users.vue"),
      },
      {
        path: "/admin/users/:id",
        name: "adminUser",
        component: () => import("@/views/Authenticated/Admin/Users.vue"),
      },
      {
        path: "/admin/articles",
        name: "adminArticles",
        component: () => import("@/views/Authenticated/Admin/Articles.vue"),
      },
      {
        path: "/admin/articles/new",
        name: "adminArticleCreate",
        component: () => import("@/views/Authenticated/Admin/Articles.vue"),
      },
      {
        path: "/admin/articles/:id",
        name: "adminArticle",
        component: () => import("@/views/Authenticated/Admin/Articles.vue"),
      },
      {
        path: "/admin/feeds",
        name: "adminFeeds",
        component: () => import("@/views/Authenticated/Admin/Feeds.vue"),
      },
      {
        path: "/admin/feeds/new",
        name: "adminFeedsCreate",
        component: () => import("@/views/Authenticated/Admin/Feeds.vue"),
      },
      {
        path: "/admin/feeds/:id",
        name: "adminFeed",
        component: () => import("@/views/Authenticated/Admin/Feeds.vue"),
      },
      {
        path: "/admin/sites",
        name: "adminSites",
        component: () => import("@/views/Authenticated/Admin/Sites.vue"),
      },
      {
        path: "/admin/sites/:id",
        name: "adminSite",
        component: () => import("@/views/Authenticated/Admin/Sites.vue"),
      },
      {
        path: "/admin/categories",
        name: "adminArticleCategorys",
        component: () => import("@/views/Authenticated/Admin/Categories.vue"),
      },
      {
        path: "/admin/categories/:id",
        name: "adminArticleCategory",
        component: () => import("@/views/Authenticated/Admin/Categories.vue"),
      },
    ],
  },
];

const authenticatedRoutes = [
  {
    path: "/profile",
    name: "Profile",
    component: () => import("@/views/Authenticated/Profile.vue"),
  },
];

const commerceRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    component: () => import("@/views/Authenticated/Dashboard.vue"),
  },
  {
    path: "/loans",
    name: "Loans",
    component: () => import("@/views/Authenticated/Loans.vue"),
  },
];

const commerceAdminRoutes = [];

let routes = [
  {
    path: "/:pathMatch(.*)*",
    name: "Not Found",
    component: () => import("@/views/NotFound.vue"),
  },
];

if (config.appType.toLowerCase() === "admin") {
  routes = routes.concat(siteAdminRoutes);
} else {
  routes = routes.concat(siteRoutes);
}
if (config.commerce ?? true) {
  routes = routes.concat(commerceRoutes);
  routes = routes.concat(commerceAdminRoutes);
}
if (config.loginEnabled ?? true) {
  routes = routes.concat(authenticatedRoutes);
}

export const createRouter = () => {
  console.log("createRouter");
  console.log(config.siteId);
  if (config.siteId) {
    api.site.get(config.siteId).then((data) => {
      console.log(data);
    });
  }

  const router = _createRouter({
    history: import.meta.env.SSR
      ? createMemoryHistory("/")
      : createWebHistory("/"),
    routes,
  });

  router.beforeEach((to, from, next) => {
    const userPaths = ["/dashboard", "/profile"];
    const authRequired =
      to.path.startsWith("/admin") || userPaths.includes(to.path);
    const loggedIn =
      typeof window !== "undefined" && window.sessionStorage.getItem("user");

    if (authRequired && !loggedIn) {
      next("/login");
    } else {
      next();
    }
  });

  return router;
};
